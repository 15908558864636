import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'folder/Inbox',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'view-frenchises',
    loadChildren: () => import('./view-frenchises/view-frenchises.module').then( m => m.ViewFrenchisesPageModule)
  },
  {
    path: 'add-frenchises',
    loadChildren: () => import('./add-frenchises/add-frenchises.module').then( m => m.AddFrenchisesPageModule)
  },
  {
    path: 'home-admin',
    loadChildren: () => import('./home-admin/home-admin.module').then( m => m.HomeAdminPageModule)
  },
  {
    path: 'edit',
    loadChildren: () => import('./franchises/edit/edit.module').then( m => m.EditPageModule)
  },
  {
    path: 'view-details',
    loadChildren: () => import('./franchises/view-details/view-details.module').then( m => m.ViewDetailsPageModule)
  },
  {
    path: 'view-student',
    loadChildren: () => import('./franchises/view-student/view-student.module').then( m => m.ViewStudentPageModule)
  },
  {
    path: 'franchise',
    loadChildren: () => import('./inquiry/franchise/franchise.module').then( m => m.FranchisePageModule)
  },
  {
    path: 'student',
    loadChildren: () => import('./inquiry/student/student.module').then( m => m.StudentPageModule)
  },
  {
    path: 'franchise-request',
    loadChildren: () => import('./inquiry/franchise-request/franchise-request.module').then( m => m.FranchiseRequestPageModule)
  },
  {
    path: 'view-franchise',
    loadChildren: () => import('./inquiry/view-franchise/view-franchise.module').then( m => m.ViewFranchisePageModule)
  },
  {
    path: 'view-inquiry-student',
    loadChildren: () => import('./inquiry/view-inquiry-student/view-inquiry-student.module').then( m => m.ViewInquiryStudentPageModule)
  },
  {
    path: 'add-questions',
    loadChildren: () => import('./add-questions/add-questions.module').then( m => m.AddQuestionsPageModule)
  },
  {
    path: 'certificate-marksheet',
    loadChildren: () => import('./certificate-marksheet/certificate-marksheet.module').then( m => m.CertificateMarksheetPageModule)
  },
  {
    path: 'view-franchise-request',
    loadChildren: () => import('./view-franchise-request/view-franchise-request.module').then( m => m.ViewFranchiseRequestPageModule)
  },
  {
    path: 'view-questions',
    loadChildren: () => import('./view-questions/view-questions.module').then( m => m.ViewQuestionsPageModule)
  },
  {
    path: 'edit-questions',
    loadChildren: () => import('./edit-questions/edit-questions.module').then( m => m.EditQuestionsPageModule)
  },
  {
    path: 'edit-student',
    loadChildren: () => import('./edit-student/edit-student.module').then( m => m.EditStudentPageModule)
  },
  {
    path: 'document-generator',
    loadChildren: () => import('./document-generator/document-generator.module').then( m => m.DocumentGeneratorPageModule)
  },
  {
    path: 'deleted-franchises',
    loadChildren: () => import('./deleted-franchises/deleted-franchises.module').then( m => m.DeletedFranchisesPageModule)
  },
  {
    path: 'generate',
    loadChildren: () => import('./generate/generate.module').then( m => m.GeneratePageModule)
  },
  {
    path: 'gallery',
    loadChildren: () => import('./upload-images/gallery/gallery.module').then( m => m.GalleryPageModule)
  },
  {
    path: 'banner',
    loadChildren: () => import('./upload-images/banner/banner.module').then( m => m.BannerPageModule)
  },
  {
    path: 'new-student-list',
    loadChildren: () => import('./new-student-list/new-student-list.module').then( m => m.NewStudentListPageModule)
  },
  {
    path: 'test-pending-student-list',
    loadChildren: () => import('./test-pending-student-list/test-pending-student-list.module').then( m => m.TestPendingStudentListPageModule)
  },
  {
    path: 'youtube-links',
    loadChildren: () => import('./youtube-links/youtube-links.module').then( m => m.YoutubeLinksPageModule)
  },
 
 
 
 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
